import {API} from '../config';

export const signIn = (user) => {
    return fetch(
        `${API}/users/signin`,
        {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-Type":"application/json",
            },
            body: JSON.stringify(user)
        }
    )
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        })
}

export const authenticate = (data, next) => {
    if(typeof window != 'undefined') {
        localStorage.setItem('jwt', JSON.stringify(data));
        next();
    }
}

export const listAllUsers = () => {
    return fetch(
      `${API}/users`, {
          method: 'GET',
          headers: {
              Accept: "application/json",
          }
      }
    )
      .then(response => {
          return response.json();
      })
      .catch(err => {
          console.log(err);
      })
}

export const getUserById = (userId) => {
  return fetch(
    `${API}/users/${userId}`,
    {
      method: 'GET',
      headers: {
        Accept: "application/json",
      }
    })
      .then(response => {
        return response.json();
      })
      .catch(err => {
        console.log(err);
      })
}

export const createUser = (user) => {
  return fetch(
    `${API}/users/create`,
    {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type":"application/json",
      },
      body: JSON.stringify(user)
    }
    )
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.log(err);
    })
  }
  
  export const updateUser = (userId, user) => {
    return fetch(
      `${API}/users/${userId}`,
      {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type":"application/json",
        },
        body: JSON.stringify(user)
      }
      )
      .then(response => {
        return response.json();
      })
      .catch(err => {
        console.log(err);
      })
    }
    
    export const deleteUser = (userId) => {
      return fetch(
        `${API}/users/${userId}`,
        {
          method: 'DELETE',
          headers: {
            Accept: "application/json",
          }
        })
          .then(response => {
            return response.json();
          })
          .catch(err => {
            console.log(err);
          })
    }

export const isAuthenticated = () => {
    if(typeof window === 'undefined'){ 
        return false;
    }
    if (localStorage.getItem('jwt')) {
        return JSON.parse(localStorage.getItem('jwt'))
    } else {
        return false;
    }
}

export const signout = (next) => {
    if (typeof window !== 'undefined') {
      localStorage.removeItem('jwt');
      return fetch(`${API}/users/signout`, {
        method: 'GET',
      })
        .then(response => {
            console.log('signout',response);
        })
        .catch( err => console.log(err));
    }
}

