import React, {useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { getUserById, updateUser, deleteUser } from '../services/apiAuth';
import Navbar from '../functional/Navbar';
import './UpdateUser.css'

const UpdateUser = ({match}) => {
  const [redirectToTracker, setRedirectToTracker] = useState(false);
  const [user, setUser] = useState({
    Fullname: "",
    Username: "",
    Country: "",
    Clearance: "",
    Dashboard_Id1: "",
    Dashboard_Id2: "",
    Dashboard_Id3: "",
    Dashboard_Id4: "",
    Dashboard_Id5: "",
    FormData: '',
  });
  
  const {
    Fullname,
    Username,
    Country,
    Clearance, 
    Dashboard_Id1,
    Dashboard_Id2,
    Dashboard_Id3,
    Dashboard_Id4,
    Dashboard_Id5,
    formData,
  } = user;
  
  useEffect(() => {
    setUser({...user, formData: new FormData()})
    fetchUser(match.params.userId)
  }, [])

  const fetchUser = (userId) => {
    getUserById(userId).then(data => {
      try {
        if (data.error) {
          setUser({...user});
        } else {
          setUser({
            ...user,
            Fullname: data.Fullname,
            Username: data.Username,
            Country: data.Country,
            Clearance: data.Clearance,
            Dashboard_Id1: data.Dashboard_Id1,
            Dashboard_Id2: data.Dashboard_Id2,
            Dashboard_Id3: data.Dashboard_Id3,
            Dashboard_Id4: data.Dashboard_Id4,
            Dashboard_Id5: data.Dashboard_Id5,
            formData: new FormData()
          })
        }
      } catch (error) {
        
      }
    })
  }

  const handleChange = name => event => {
    const value = event.target.value;
    formData.set(name, value);

    setUser({...user, [name]:value})
  }

  const clickSubmit = event => {
    event.preventDefault();

    let {Username, Country, Clearance, Dashboard_Id1, Dashboard_Id2, Dashboard_Id3, Dashboard_Id4, Dashboard_Id5} = user;
    updateUser(match.params.userId, user).then(data => {
      try {
        if (data.error) {
          setUser({...user})
        } else {
          setUser({...user});
          setRedirectToTracker(true);
        }
      } catch (error) {
        console.log(error);
      }
    })
  }

  const clickDelete = (userId) => {
    deleteUser(match.params.userId).then((data) => {
      try {
        if (data.error) {
          setUser({...user})
        } else {
          setRedirectToTracker(true);
        }
      } catch (error) {
        console.log(error);
      }
    })
  } 

  const redirect = () => redirectToTracker ? <Redirect to="/manageusers"/> : "";

  const updateUserForm = () => (
    <form action="" className="form-group user-form" onSubmit={clickSubmit}>
      <h2 className="mb-5">{Fullname}</h2>
      <label>Username</label>
      <input 
        className="form-control" 
        onChange={handleChange('Username')}
        type="text" 
        placeholder="Username" 
        value={Username}/>
      <label>Country</label>
      <input 
        className="form-control" 
        onChange={handleChange('Country')}
        type="text" 
        placeholder="Country" 
        value={Country}/>
      <label>Clearance</label>
      <input 
        className="form-control" 
        onChange={handleChange('Clearance')}
        type="text" 
        placeholder="Clearance" 
        value={Clearance}/>
      <label>Dashboard Id</label>
      <input 
        className="form-control" 
        onChange={handleChange('Dashboard_Id1')}
        type="text" 
        placeholder="Dashboard Id 1" 
        value={Dashboard_Id1}/>
      <label>Dashboard Id 2</label>
      <input 
        className="form-control" 
        onChange={handleChange('Dashboard_Id2')}
        type="text" 
        placeholder="Dashboard Id 2" 
        value={Dashboard_Id2}/>
      <label>Dashboard Id 3</label>
      <input 
        className="form-control" 
        onChange={handleChange('Dashboard_Id3')}
        type="text" 
        placeholder="Dashboard Id 3" 
        value={Dashboard_Id3}/>
      <label>Dashboard Id 4</label>
      <input 
        className="form-control" 
        onChange={handleChange('Dashboard_Id4')}
        type="text" 
        placeholder="Dashboard Id 4" 
        value={Dashboard_Id4}/>
      <label>Dashboard Id 5</label>
      <input 
        className="form-control" 
        onChange={handleChange('Dashboard_Id5')}
        type="text" 
        placeholder="Dashboard Id 5" 
        value={Dashboard_Id5}/>
      <button className="btn btn-outline-success mr-3">Update</button>
      <button className="btn btn-danger" onClick={clickDelete}>Delete</button>
    </form>
  )

  return (
    <>
      <Navbar/>
      <div className="container mt-5">
        <h1 style={{textAlign: "center"}}>Update User</h1>
        {updateUserForm()}
        {redirect()}
      </div>
    </>
  )
}

export default UpdateUser;