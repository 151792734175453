import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated} from '../services/apiAuth';

const TrackerUser = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => isAuthenticated() && typeof(isAuthenticated()) 
        !== undefined && (isAuthenticated().user.Clearance < 2 || isAuthenticated().user.Clearance == 3 ) ? (
        <Component {...props} />
    ) : isAuthenticated().user.Clearance < 3 ? (
        <Redirect to={{pathname: "/dashboard", state: {from: props.location}}} />
    ) : (
        <Redirect to={{pathname: "/trucks", state: {from: props.location}}} />
        )
    } />
)

export default TrackerUser;
