import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe';
import { isAuthenticated } from '../services/apiAuth';
import { postDashboardId, getDashboards } from '../services/apiUser';

import Navbar from '../functional/Navbar';
import "./Dashboard.css"

const Dashboard = () => {
  const {
    user: 
    {
      Dashboard_Id1, 
      Dashboard_Id2,
      Dashboard_Id3,
      Dashboard_Id4,
      Dashboard_Id5,
      Dashboard_Name1, 
      Dashboard_Name2,
      Dashboard_Name3,
      Dashboard_Name4,
      Dashboard_Name5,
      QS_Username
    }
  } = isAuthenticated();  

  // this values are gotten from the database and here we are 
  // creating our own dictionary
  const dashboards = {
    board1: {name: Dashboard_Name1, id: Dashboard_Id1},
    board2: {name: Dashboard_Name2, id: Dashboard_Id2},
    board3: {name: Dashboard_Name3, id: Dashboard_Id3},
    board4: {name: Dashboard_Name4, id: Dashboard_Id4},
    board5: {name: Dashboard_Name5, id: Dashboard_Id5}
  }

  // here we but the dictionary values in an array
  const dashboard_names = [
    dashboards.board1, 
    dashboards.board2, 
    dashboards.board3, 
    dashboards.board4, 
    dashboards.board5
  ];

  // here we are cleaning the values of the arr in order to display the selector in a more clean manner
  let arr2 = [];
  for(let i = 0; i < dashboard_names.length-1; i++) {
    if (dashboard_names[i].name !== null) {
      arr2.push(dashboard_names[i])
    }
  }

  console.log(arr2);
  
  const [values, 
    setValues] = useState({
    EmbedUrl: '',
    loading: false,
    chosen_id: '',
    chosen: false
  });
  
  const {
    EmbedUrl,
    loading,
    chosen_id,
    chosen
  } = values;

  useEffect(() => {   
    setValues({...values, chosen_id: Dashboard_Id1})
  }, []);

  const chooseDashboardId = () => {
    return (
      <select value={chosen_id} className="form-control sel-box" onChange={setDashBoardId}>
        <option disabled>Choose Dashboard</option>
        {
          arr2.map((b, i) => (
            <option key={i} value={b.id}>{b.name}</option>
          ))
        }
      </select>
    )
  }

  const setDashBoardId = event => {
    const option = event.target.value
    setValues({...values, chosen_id: option});
  }

  const sendBackendDashboardId = () => {
    let obj = {qs_dashboard_Id: chosen_id, qs_user_Id: QS_Username};
    setValues({...values, loading: true});
    postDashboardId(obj).then(data => {
      if (data === undefined) {
        return;
      }
      try {
        setValues({...values, EmbedUrl: data.EmbedUrl, chosen: true});
      } catch (error) {
        console.log(error)
      }
    });
  }

  const Selector = () => {
    return (
      <div className="contanier mt-5">
        <div className="row">
          <div className="col-4 offset-4 ">
            {chooseDashboardId()}
          </div>
          <div className="col-4">
            <button className="btn sel-btn" 
              onClick={sendBackendDashboardId}>
                Select Dashboard
            </button>
          </div>
        </div>
      </div>
    )
  }

  const showLoading = () =>
    loading && (
      <div className="alert alert-info">
        <h2>Loading...</h2>
      </div>
    );

  return (  
    <>
      <Navbar/>
      { !chosen &&
        <Selector/>
      }
      {loading &&  (
        <div className="container" style={{marginTop: '120px'}}>
          {showLoading()}
        </div>  
      )}
      { Dashboard_Id1 && 
        (
        <div className="container-fluid dash-box">
          <Iframe 
            className="qs-frame"
            url={EmbedUrl}
            width="100%"
            id="myId"
            position="relative"
          />
        </div>
        )
      }
      {
        EmbedUrl === '' || EmbedUrl === null && (
          <div className="cointainer">
            <h2 style={{textAlign: 'center', marginTop: '120px'}}>You currently don't have a Dashboard assigned to you</h2>
          </div>
        )
      }
    </>
  )
}

export default Dashboard;