import { API } from '../config';
import queryString from 'querystring';

export const getShipments = (country,clearance) => {
  return fetch(
    `${API}/shipments`,
    {
      method: 'GET',
      headers: {
        Accept: "application/json",
      }
    }
  )
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
}

export const getShipment = (shipmentId) => {
  return fetch(
    `${API}/shipments/${shipmentId}`,
    {
      method: 'GET',
      headers: {
        Accept: "application/json",
      }
    })
      .then(response => {
        return response.json();
      })
      .catch(err => {
        console.log(err);
      })
}

export const writeChangeLog = (shipment) => {
  return fetch(`${API}/shipments/changelog`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type":"application/json",
    },
    body: JSON.stringify(shipment)
  })
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.log(err);
      return;
    })
}

export const writeCargoWiseLog = (shipment) => {
  return fetch(`${API}/shipments/changelog`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type":"application/json",
    },
    body: JSON.stringify(shipment)
  })
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.log(err);
      return;
    }) 
}

export const updateShipment = (shipmentId, shipment) => {
  return fetch(`${API}/shipments/${shipmentId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type":"application/json",
    },
    body: JSON.stringify(shipment)
  })
    .then(response => { 
      return response.json();
    })
    .catch(err => {
      console.log(err);
    })
}

export const updateStrings = (shipmentId, shipment) => {
  return fetch(`${API}/shipments/updatexml/${shipmentId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type":"application/json",
    },
    body: JSON.stringify(shipment)
  })
    .then(response => {
      return response.json(); // apparently this is better instead of response.json() because the response is empty.
    })
    .catch(err => {
      console.log(err);
    })
}

export const updateXml = (shipmentId, flags) => {
  return fetch(`
    ${API}/shipments/update/${shipmentId}`,{
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type":"application/json",
      },
      body: JSON.stringify({flags})
    }
    )
    .then(response => {
      console.log(flags)
      return response.status(204).json();
    })
    .catch(err => {
      console.log("There was an error: ", err);
    })
}

export const searchShipment = (params, Country, Clearance) => {
  const query = queryString.stringify(params);
  console.log("QUERY FORM params: ", query);
  return fetch(
    `${API}/shipments/search?${query}`,
    {
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type":"application/json",
      },
      body: JSON.stringify({Clearance, Country})
    })
    .then(response => {
      console.log("SHIPMENT: ",params);
      return response.json();
    })
    .catch(err => {
      console.log(err);
    })
}