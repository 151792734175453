import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated} from '../services/apiAuth';

const TruckUser = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => isAuthenticated() && typeof(isAuthenticated()) 
      !== undefined &&  isAuthenticated().user.Clearance == 4 || isAuthenticated().user.Clearance == 0 ? (
        <Component {...props} />
    ) : (
        <Redirect to={{pathname: "/", state: {from: props.location}}} />
    )} />
)

export default TruckUser;
