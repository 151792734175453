import { API } from '../config';

export const getPastDueShipments = (country, clearance) => {
  return fetch(
    `${API}/pastdue`,{
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
    .then(response => {
        return response.json();
    })
    .catch(err => {
        console.log(err);
    })
}