import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

// View Components
import Home from './core/Home';
import Tracker from './core/Tracker';
import UpdateShipment from './core/UpdateShipment';
import Signin from './auth/Signin';
import Signup from './auth/Signup';
import Dashboard from './core/Dashboard';

// Functional Components
import AdminRoute from './functional/AdminRoute';
import Testing from './core/Testing';
import PastDue from './core/PastDue';
import DashboardUser from './functional/DashboardUser';
import TrackerUser from './functional/TrackerUser';
import SuperUser from './functional/SuperUser';
import ManageUsers from './core/ManageUsers';
import UpdateUser from './core/UpdateUser';
import AddUser from './admin/AddUser';
import Trucks from './core/Trucks';
import Fife from './core/Fife';
import Fairview from './core/Fairview';
import EditTruckFairview from './core/EditTruckFairview';
import EditTruckFife from './core/EditTruckFife';
import Documentation from './core/Documentation';
import TruckUser from './functional/TruckUser';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Signin}/>
        <TrackerUser path="/tracker" exact component={Tracker} />
        <DashboardUser path="/dashboard" exact component={Dashboard}/>
        <AdminRoute path="/test" exact component={Testing}/>
        <SuperUser path="/signup" exact component={Signup}/>
        <SuperUser path="/adduser" exact component={AddUser} />
        <SuperUser path="/manageusers" exact component={ManageUsers}/>
        <TruckUser path="/trucks" exact component={Trucks}/>
        <Route path="/trucks/fife" exact component={Fife}/>
        <Route path="/trucks/fairview" exact component={Fairview}/>
        <Route path="/trucks/editFairview/:truckId" exact component={EditTruckFairview}/>
        <Route path="/trucks/editFife/:truckId" exact component={EditTruckFife}/>
        <SuperUser path="/edituser/:userId" exact component={UpdateUser}/>
        <AdminRoute path="/documentation" exact component={Documentation}/>
        <AdminRoute path="/pastdue" exact component={PastDue}/>
        <Route path="/shipments/edit/:shipmentId" exact component={UpdateShipment}/>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes;