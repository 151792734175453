import React, { useState, useEffect } from 'react';
import Navbar from '../functional/Navbar.js'
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import Iframe from 'react-iframe';
import { getAwsUrl } from '../services/apiUser';

import "./Testing.css"

const Testing = () => {
  const [values, setValues] = useState({
    EmbedUrl: ''
  });

  const {
    EmbedUrl,
  } = values;

  const getUrl = () => {
    getAwsUrl().then(data => {
      try {
        setValues(data);
  
      } catch (error) {
        setValues({
          ...values, 
          EmbedUrl: encodeURI(data.EmbedUrl)
        })
      }
    })
  }

  const onDashboardLoad = (payload) => {
    console.log("Do something when the dashboard is fully loaded.");
  }

  const embedDashboard = () => {
    let containerDiv = document.getElementById("dashboardContainer");
    let options = {
      url: `"${EmbedUrl}"`,
      container: containerDiv,
      parameters: {
          country: "United States",
          states: [
              "California",
              "Washington"
          ]
      },
      scrolling: "no",
      height: "700px",
      width: "1000px",
      locale: "en-US",
      footerPaddingEnabled: true
  };
    let dashboard = QuickSightEmbedding.embedDashboard(options);
    dashboard.on("error", onError);
    dashboard.on("load", onDashboardLoad);
  } 

  const onError = (payload) => {
    console.log("Do something when the dashboard fails loading");
  }

  // const onCountryChange = (obj) => {
  //   dashboard.setParameters({country: obj.value});
  // }


  useEffect(() => {   
    getUrl();
  }, []);

  return (
    <>
    <Navbar/>
    <Iframe 
      style={{margin: "0"}}
      className="qs-frame"
      url={EmbedUrl}
      width="1920px"
      height="1080px"
      id="myId"
      position="relative"
    />
      <div className="container-fluid">
      <h1 className="title">Dashboard Route</h1>
        <br/>    
        <body onLoad="">
          <span>
              <label for="country">Country</label>
              <select id="country" name="country" onchange="onCountryChange(this)">
                  <option value="United States">United States</option>
                  <option value="Mexico">Mexico</option>
                  <option value="Canada">Canada</option>
              </select>
          </span>
          <div id="dashboardContainer"></div>
        </body>
      </div>
    </>
  )
}

export default Testing; 