import React, {useState, useEffect} from 'react';
import { Spinner } from 'reactstrap';

import  Navbar  from '../functional/Navbar';
import { getPastDueShipments } from '../services/apiPastDue';
import { formatDate } from '../helpers/formatterFunctions';

import './PastDue.css'

const PastDue = () => {
  const [shipments, setShipments] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchShipments = () => {
    getPastDueShipments().then(data => {
      if (data) {
        try {
          console.log("This is the data", data[0]);
          setShipments(data);
          setLoading(false);
          console.log(data)
        } catch (error) {
          console.log(data.error);
        }
      }
    })
  }

  const showSpinner = () => 
    loading && (
    <div className="container spin-cont mg-5">
      <Spinner className="spinner" />{' '}
      <h4>Loading ...</h4>
    </div>
  )  

  useEffect(() => {
    fetchShipments();
  }, [])

  return (
    <>
      <Navbar/>
      <div className="container mt-5">
        <h1>Past Due</h1>

        {showSpinner()}
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="cont">
                <th scope="row header">Vortex Ref. No.</th>
                <th scope="col">Master Bill of Lading</th>
                <th scope="col">House Bill </th>
                <th scope="col">ETA </th>
              </tr>
            </thead>
            { shipments && shipments.map((shipment, i) => (
              <tbody key={i}>
                <tr className="data">
                  <td>{shipment.Vortex_Reference}</td>
                  <td>{shipment.Master_Bill}</td>
                  <td>{shipment.House_Bill}</td>
                  <td>{formatDate(shipment.ETA)}</td>
                </tr>
              </tbody>
              )
            )}
          </table>
        </div>
      </div>
    </>
  )
}

export default PastDue;