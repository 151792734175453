import { API } from '../config';
import queryString from 'querystring';

export const getTrucksFife = () => {
  return fetch(
    `${API}/trucks/fife`,
    {
      method: 'GET',
      headers: {
        Accept: "application/json",
      }
    }
  )
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
}

export const getTrucksFairview = () => {
  return fetch(
    `${API}/trucks/fairview`,
    {
      method: 'GET',
      headers: {
        Accept: "application/json",
      }
    }
  )
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
}

export const getFairviewTruckById = (truckId) => {
  return fetch(
    `${API}/trucks/fairview/${truckId}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
      }
    }
  )
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
}

export const getFifeTruckById = (truckId) => {
  return fetch(
    `${API}/trucks/fife/${truckId}`, {
      method: 'GET',
      headers: {
        Accept: "application/json",
      }
    }
  )
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
}

export const updateTruck = (truckId, truck) => {
  return fetch(
    `${API}/trucks/${truckId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type":"application/json",
      },
      body: JSON.stringify(truck)
    }
  )
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.log(err);
    })
}