import { API } from "../config";

export const getAwsUrl = () => {
  return fetch(
    `${API}/aws`, {
    method: "GET",
    headers: {
      Accept: "application/json"
    }
  })
  .then((response) => {
    return response.json();
  })
  .catch((err) => {
    console.log(err);
  });
};

export const getDashboards = () => {
  return fetch(
    `${API}/dashboard`, {
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    })
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.log(err);
    })
}

export const postDashboardId = (id) => {
  return fetch(
    `${API}/aws/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(id)
  })
  .then(response => {
    return response.json();
  })
  .catch(err => {
    console.log(err);
    return;
  })
}