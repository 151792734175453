import React, {useEffect, useState} from 'react';
import { Button, Spinner } from 'reactstrap';
import {Link, Redirect} from 'react-router-dom';
import { listAllUsers, createUser } from '../services/apiAuth';
import Navbar from '../functional/Navbar';
import './ManageUsers.css';

const ManageUsers = () => {

  const [users, setUsers] = useState([])
  const [redirectToManage, setRedirectToManage] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [user, setUser] = useState({
    Fullname: "",
    Country: "",
    Password: "",
    Username: "",
    Clearance: "",
    Dashboard_Id: ""
  });
  
  const {
    Fullname,
    Country,
    Password,
    Username,
    Clearance, 
    Dashboard_Id
  } = user;

  useEffect(() => {
    fetchUsers();
  }, [])

  const AddUser = () => setAddUser(!addUser)

  const redirect = () => redirectToManage ? <Redirect to="/manageusers"/> : "";

  const handleChange = name => event => {
    const value = event.target.value;
    setUser({...user, [name]:value})
  }

  const fetchUsers = () => {
    listAllUsers().then(data => {
      if (data) {
        try {
          setUsers(data);
          setLoading(false);
          console.log(data)
        } catch (error) {
          console.log(data.error);
        }
      }
    })
  }

  
  const showSuccess = () =>
    success && (
      <div className="alert alert-success">
        <h2>User was succesfully added!</h2>
      </div>
    );

  // const showSpinner = () => 
  //   loading && (
  //   <div className="container spin-cont mg-5">
  //     <Spinner className="spinner" />{' '}
  //     <h4>Loading ...</h4>
  //   </div>
  // )  

  const clickSubmit = event => {
    event.preventDefault();
    console.log("User before: ", user);
    let {Fullname, Username, Country, Clearance, Password, Dashboard_Id} = user;
    console.log("User before: ", user);
    createUser(user).then(data => {
      try {
        if (data.error) {
          setUser({...user})
        } else {
          setUser({
            ...user,
            Fullname: '',
            Username: '',
            Country: '',
            Clearance: '',
            Password: '',
            Dashboard_Id: ''
          });
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false)
          }, 2000)
          setAddUser(false);
          fetchUsers();
          redirectToManage(true);
        }
      } catch (error) {
        console.log(error);
      }
    })
  }

  const addUserForm = () => (
    <>
    <thead>
      <tr className="add-form">
        <td scope="col row header">
          <input 
            onChange={handleChange('Fullname')}
            value={Fullname} 
            className="form-control" 
            type="text" 
            placeholder="Fullname"/>
        </td>
        <td scope="col">
          <input 
            onChange={handleChange('Username')}
            value={Username} 
            className="form-control" 
            type="text" 
            placeholder="Username"/>
        </td>
        <td scope="col">
          <input 
            onChange={handleChange('Country')}
            value={Country} 
            className="form-control" 
            type="text" 
            placeholder="Country"/>
        </td>
        <td scope="col">
          <input 
            onChange={handleChange('Clearance')}
            value={Clearance} 
            className="form-control" 
            type="text" 
            placeholder="Clearance"/>
        </td>
        <td scope="col">
          <input 
            onChange={handleChange('Password')}
            value={Password} 
            className="form-control" 
            type="text" 
            placeholder="Password"/>
        </td>
        <td scope="col">
          <input 
            onChange={handleChange('Dashboard_Id')}
            value={Dashboard_Id} 
            className="form-control" 
            type="text" 
            placeholder="Dasboard Id"/>
        </td>
        <td scope="col">
          <button className="btn" onClick={clickSubmit}>Add</button>
        </td>
      </tr>
    </thead>
    </>
  );

  return (
    <>
      <Navbar/>
      {showSuccess()}
      <h1 style={{textAlign: 'center', marginTop: '120px'}}>See all the users here</h1>
      <div className="container-fluid">
        <button className="btn add-user" onClick={AddUser}>
          <i className="fa fa-user"></i>  
        </button>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr className="cont">
                <th scope="row header">Name</th>
                <th scope="row header">Username</th>
                <th scope="col">Country</th>
                <th scope="col">Clearance </th>
                <th scope="col">Password</th>
                <th scope="col">Dashboard Id </th>
                <th scope="col">Dashboard Id2 </th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            { addUser && 
              addUserForm()
            }
            {redirect()}
            {users && users.map((user, i) => (
              <tbody key={i}>
              <tr className="data">
                <td>{user.Fullname}</td>
                <td>{user.Username}</td>
                <td>{user.Country}</td>
                <td>{user.Clearance}</td>
                <td>{user.Password}</td>
                <td>{user.Dashboard_Id}</td>
                <td>{user.Dashboard_Id2}</td>
                <td>
                  <Link to={`edituser/${user.id}`}>
                    <Button className="btn-edit">Edit</Button>
                  </Link>
                </td>
              </tr>
            </tbody>
            ))}
          </table>
        </div>
      </div>
    </>
  )
}

export default ManageUsers;