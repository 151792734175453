import React from "react";
import Navbar from "../functional/Navbar";

const Documentation = () => {

  let conStyle = {
    
    textAlign: 'left'
  }

  return (
    <>
      <Navbar />
      <h1 className="m-5" style={{ textAlign: "center" }}>
        Documentation for the administrator & super user
      </h1>
      <div className="container" style={conStyle}>
        <h3>Clearances for the users</h3>
        <hr/>
        <p>Super User clearance = 0</p>
        <p>Admin clearance = 1</p>
        <p>Dashboard user = 2</p>
        <p>Milestone user = 3</p>
        <p>Truck Shipments = 4</p>
      </div>
    </>
  );
};

export default Documentation;
