import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated} from '../services/apiAuth';

const SuperUser = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => isAuthenticated() && isAuthenticated().user.Clearance < 1 ? (
        <Component {...props} />
    ) : (
        <Redirect to={{pathname: "/", state: {from: props.location}}} />
    )} />
)

export default SuperUser;
