import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { signIn, authenticate } from "../services/apiAuth";

import Navbar from "../functional/Navbar";
import Footer from '../functional/Footer';
import "./Signin.css";

const Signin = () => {
  const [values, setValues] = useState({
    username: "",
    password: "",
    error: "",
    loading: false,
    redirectToReferrer: false
  });

  const { username, password, loading, error, redirectToReferrer } = values;

  const handleChange = name => event => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const clickSubmit = event => {
    event.preventDefault();
    setValues({ ...values, error: false, loading: true });
    signIn({ username, password }).then(response => {
      if (response.error) {
        setValues({ ...values, error: response.error, loading: false });
      } else {
        authenticate(
          response, () => {
            setValues({ ...values, redirectToReferrer: true });
          }
        )
        console.log("its all gooooood");
      }
    });
  };

  const signInForm = () => (
    <form className="sign-box">
      <h4>Sign in</h4>
      <div className="form-group">
        <label className="text-muted">Username</label>
        <input
          onChange={handleChange("username")}
          type="username"
          className="form-control"
          value={username}
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Password</label>
        <input
          onChange={handleChange("password")}
          type="password"
          className="form-control"
          value={password}
        />
      </div>
      <button className="s-btn btn btn-primary" onClick={clickSubmit}>
        Sign In
      </button>
    </form>
  );

  const showError = () => (
    <div
      className="alert alert-danger"
      style={{ display: error ? "" : "none" }}
    >
      {error}
    </div>
  );

  const showLoading = () =>
    loading && (
      <div className="alert alert-info">
        <h2>Loading...</h2>
      </div>
    );

  const redirectUser = () => {  
    if (redirectToReferrer) return <Redirect to="/tracker" />;
  }

  return (
    <>
      <Navbar />
      {showLoading()}
      {showError()}
      <div className="b-cont container">
        <div className="row">
          <div className=" col-md-6">
            {signInForm()}
          </div>
          <div className="col-md-6">
            <img className="img-b" src="/img/iso_signin.jpg"/>
          </div>
        </div>
      </div>
      {redirectUser()}
      <Footer/>
    </>
  );
};

export default Signin;
