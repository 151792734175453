import React, {useState} from 'react';
import {
  Collapse, 
  Navbar, 
  NavbarToggler, 
  NavbarBrand, 
  Nav, 
  NavItem, 
  NavbarText, 
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';

import "./Navbar.css";
import { isAuthenticated, signout } from '../services/apiAuth';

const NavbarComponent = ({history}) => {
  const [isOpen, setIsOpen] = useState(false);
  if (isAuthenticated()) {
    const {user: {id, Fullname, Clearance, Country}} = isAuthenticated();
  }
  const toggle = () => setIsOpen(!isOpen);

  const isActive = (history, path) => {
    if (history.location.pathname === path) {
      return { color: `#000`};
    } else {
      return { color: `#808080`};
    }
  }

  return (
    <div>
      <Navbar className="su-nav" color="light" light expand="md">
        <div className="container">
        <NavbarBrand>
          <img className="logo" src="/img/logo.jpeg"/>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            { 
              isAuthenticated() && (isAuthenticated().user.Clearance <= 1  || isAuthenticated().user.Clearance == 3 )  && (
                <>
                  <NavItem>
                    <Link 
                      className="nav-link" 
                      to="/tracker" 
                      style={isActive(history, '/tracker')}>
                      Milestones
                    </Link>
                  </NavItem>
                </>
              )
            }
            {
              isAuthenticated() && isAuthenticated().user.Clearance <= 1 && (
                <>
                <NavItem>
                  <Link 
                    className="nav-link" 
                    to="/pastdue"
                    style={isActive(history, "/pastdue")}>
                    Past Due
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/documentation" className="nav-link nav-item">Documentation</Link>
                </NavItem>
                </>
              )
            }
          </Nav>
          <NavbarText>
            <Nav>
              { !isAuthenticated() && (
                <>
                  <NavItem>
                    <Link to="/" className="nav-link nav-item btn-login mr-2">Log In</Link>
                  </NavItem>
                </>
              )}
              {isAuthenticated() && isAuthenticated().user.Clearance === '0' && (
                <>
                  <NavItem>
                    <Link to="/manageusers" className="nav-link nav-item">Manage Users</Link>
                  </NavItem>
                </>

              )}
              { isAuthenticated() && (isAuthenticated().user.Clearance === '4' || isAuthenticated().user.Clearance === '0') &&
                <NavItem>
                  <Link to="/trucks" className="nav-link nav-item">Truck Shipments</Link>
                </NavItem>
              }
              { isAuthenticated() && (isAuthenticated().user.Clearance <= 2 || isAuthenticated().user.Clearance === 1) && (
                  <>
                    <NavItem>
                      <Link  to="/dashboard" className="nav-link mr-2">Dashboard</Link>
                    </NavItem>
                  </>
                )
              }
              { isAuthenticated() && (
              <>
                <NavItem onClick={() => signout()}>
                  <Link  to="/" className="nav-link nav-item btn-register mr-2">Log Out</Link>
                </NavItem>
              </>
              )}
            </Nav>
          </NavbarText>
        </Collapse>
        </div>
      </Navbar>
    </div>
  )
}

export default withRouter(NavbarComponent);