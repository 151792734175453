export const truckXml = (obj, type) => {
  let xml1 = `<UniversalEvent>
      <Event>
          <DataContext>
              <DataTargetCollection>
                  <DataTarget>
                      <Type>ForwardingShipment</Type>
                      <Key>${obj.reference}</Key>
                  </DataTarget>
              </DataTargetCollection>
          </DataContext>
          <EventTime>${obj.estimate}</EventTime>
          <EventType>Z01</EventType>
          <IsEstimate>true</IsEstimate>
      </Event>
  </UniversalEvent>`

  let xml2 = `<UniversalEvent>
      <Event>
          <DataContext>
              <DataTargetCollection>
                  <DataTarget>
                      <Type>ForwardingShipment</Type>
                      <Key>${obj.reference}</Key>
                  </DataTarget>
              </DataTargetCollection>
          </DataContext>
          <EventTime>${obj.actual}</EventTime>
          <EventType>Z01</EventType>
          <IsEstimate>false</IsEstimate>
      </Event>
  </UniversalEvent>`

  let xml3 = `<UniversalEvent>
      <Event>
          <DataContext>
              <DataTargetCollection>
                  <DataTarget>
                      <Type>ForwardingShipment</Type>
                      <Key>${obj.reference}</Key>
                  </DataTarget>
              </DataTargetCollection>
          </DataContext>
          <EventTime>${obj.estimate}</EventTime>
          <EventType>DCF</EventType>
          <IsEstimate>true</IsEstimate>
      </Event>
  </UniversalEvent>`

  let xml4 = `<UniversalEvent>
      <Event>
          <DataContext>
              <DataTargetCollection>
                  <DataTarget>
                      <Type>ForwardingShipment</Type>
                      <Key>${obj.reference}</Key>
                  </DataTarget>
              </DataTargetCollection>
          </DataContext>
          <EventTime>${obj.actual}</EventTime>
          <EventType>DCF</EventType>
          <IsEstimate>false</IsEstimate>
      </Event>
  </UniversalEvent> `

  switch (type) {
    case 'fifeEstimate':
        obj = {case: 'estimate', stringEstimate: xml1}
        return obj;
    case 'fifeActual':
        obj = {case: 'actual', stringActual: xml2}
        return obj;
    case 'fifeBoth':
        obj = { case: 'both', stringEstimate: xml1, stringActual: xml2 }
        return obj;
    case 'fairvewEstimate':
        obj = {case: 'estimate', stringEstimate: xml3}
        return obj; 
    case 'fairviewActual':
        obj = {case: 'actual', stringActual: xml4}
        return obj
    case 'fairviewBoth':
        obj = { case: 'both', stringEstimate: xml3, stringActual: xml4 }
        return obj;
    default:
      break;
  }

}

export const checkWhichCaseFife = (truck) => {
    let string_case;
    if (truck.estimate !== null && truck.actual === '') {
      string_case = 'fifeEstimate'
      truck = truckXml(truck, string_case)

    } else if (truck.estimate === '' && truck.actual !== null) {
      string_case = 'fifeActual'
      truck = truckXml(truck, string_case)

    } else {
      string_case = 'fifeBoth'
      truck = truckXml(truck, string_case)
    }
    return truck;
  }

  export const checkWhichCaseFairview = (truck) => {
    let string_case;
    if (truck.estimate !== null && truck.actual === '') {
      string_case = 'fairvewEstimate'
      truck = truckXml(truck, string_case)

    } else if (truck.estimate === '' && truck.actual !== null) {
      string_case = 'fairviewActual'
      truck = truckXml(truck, string_case)

    } else {
      string_case = 'fairviewBoth'
      truck = truckXml(truck, string_case)
    }
    return truck;
  }