import React, {useState, useEffect} from "react";
import { Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import Navbar from "../functional/Navbar";
import { checkWhichCase, checkWhichCaseFife } from "../helpers/createTruckXml";

import { getFifeTruckById, updateTruck } from '../services/apiTrucks';
import { formatDate } from '../helpers/formatterFunctions';

const EditTruckFife = ({match}) => {
  const [redirectToTracker, setRedirectToTracker] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [truck, setTruck] = useState({
    Vortex_Reference: '',
    estimate: '',
    actual: '',
    formData: ''
  })

  const {
    Vortex_Reference,
    estimate,
    actual,
    formData
  } = truck;

  useEffect(() => {
    setTruck({...truck, 
      formData: new FormData()
    })
    fetchTruck(match.params.truckId)
  }, [])

  // method to fetch truck shipments form the api;
  const fetchTruck = (truckId) => {
    getFifeTruckById(truckId).then(data => {
      try {
        if (data.error) {
          setTruck({...truck})
        } else {
          setTruck({
            ...truck,
            Vortex_Reference: data.Vortex_Reference,
            estimate: formatDate(data.Estimated_Date_of_Delivery_to_CTDI),
            actual: formatDate(data.Actual_Date_of_Delivery_to_CTDI),
            formData: new FormData()
          })
        }
      } catch (error) {
        
      }
    })
  }
 
  // function that keeps track of state when ever you are changing the 
  // values of the input boxes
  const handleChange = name => event => {
    const value = event.target.value;
    formData.set(name, value);
    setTruck({...truck, [name]:value})
  }

  // handles the submit function processing and sends it to the backend
  const clickSubmit = (e) => {
    e.preventDefault();

    let truck = {
      reference: Vortex_Reference, 
      estimate: estimate, 
      actual: actual
    }

    const answer = window.confirm(`
      Are you sure these values are okay?

      Estimated Date: ${estimate}  
      Actual Date: ${actual}
    `)
  
    if (!answer) {
      window.location.reload();
      return;
    }

    // set submitting to he database to true
    setSubmitting(true);

    truck = checkWhichCaseFife(truck)
    console.log("Log after xml is created", truck);

    // here is where it sends the object to the backend, 
    // uses @checkWhichCase() @updateTruck and changes state
    // of the redirectToTracker variable 
    updateTruck(match.params.truckId, truck).then(data => {
      setSubmitting(true);
      try {
        if (data.error) {
          setTruck({...truck})
        } else {
          console.log('No errors');
        }
      } catch (error) {
        console.log(error);
      }
    })
    setTimeout(() => {
      setSubmitting(false)
      setRedirectToTracker(true);
    }, 4000);
    
  }

  // redirect function that triggers the redirect if the redirectToTracker 
  // is set to true
  const redirect = () => redirectToTracker ? <Redirect to="/trucks/fife"/> : "";

  // show this spinner meanwhile it calls the api and makes the changes in the backend
  const showSpinner = () =>
  submitting && (
    <div className="container spin-cont mg-5" style={{color: "orange"}}>
      <Spinner className="spinner" /> <h4>Submitting to the database ...</h4>
    </div>
  );

  // form component that keeps all the loginc of the values and changes
  // for the truck shipment
  const updateTruckForm = () => (
    <form action="" className="form-group user-form" onSubmit={clickSubmit}>
      <h2>Vortex Ref No. {Vortex_Reference}</h2>
      
      <label>Estimated Date of Delivery</label>
      <input 
        className="form-control mt-2" 
        onChange={handleChange('estimate')}
        type="text" 
        placeholder="Estimate" 
        value={estimate}/>
      <label>Actual Date of Delivery</label>
      <input 
        className="form-control" 
        onChange={handleChange('actual')}
        type="text" 
        placeholder="Actual" 
        value={actual}/>
      <button className="btn btn-outline-success mr-3">Update</button>
    </form>
  )

  return (
    <>
      <Navbar/>
      {showSpinner()}
      {!submitting && (
        <>
          <h1 className="mt-4" style={{textAlign: 'center'}}>
            Edit Fife Truck Shipment
          </h1>
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                {updateTruckForm()}
                {redirect()}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
};

export default EditTruckFife;