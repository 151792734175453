import React from 'react';
import './Footer.css';

const Footer = () => {

    return (
        <>        
          <div className="bg">
            <div className="container f-cn">
              <div className="row">
                <div className="col-md-4">
                  <a target="_blank" href="https://www.facebook.com/VORTEXWL/">
                    <i className="icon-f fa fa-facebook fa-3x"></i> 
                  </a>
                </div>
                <div className="col-md-4">
                  <a target="_blank" href="https://twitter.com/vortexworldwide">
                    <i className="icon fa fa-twitter fa-3x"></i>
                  </a>
                </div>
                <div className="col-md-4">
                  <a target="_blank" href="https://www.linkedin.com/company/vortex-worldwide-logistics/about/">
                    <i className="icon fa fa-linkedin fa-3x"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
    );
}

export default Footer;

