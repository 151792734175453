import React from "react";
import Navbar from "../functional/Navbar";
import Card from "../functional/Card";
import {svgFife, svgFairview} from '../functional/svgs'; // leaving the svgs here to avoid unnecesary clutter

const Trucks = () => {
  const fairviewCss = {
    color: "white!important",
    colorBackground: "blue",
  };

  return (
    <>
      <Navbar />
      <div className="container mt-4">
        <div className="row">
          <div className="col-6">
            <Card
              children={svgFairview}
              name="FIFE"
              image="/img/multichannel-logistik.jpg"
              url="/trucks/fife"
              subtitle="Shipments pending pickup/delivery to CTDI FIFE Facility"
            />
          </div>
          <div className="col-6">
            <Card
              children={svgFairview}
              name="FAIRVIEW"
              image="/img/fife.jpeg"
              url="/trucks/fairview"
              subtitle="Shipments pending transfer from CTDI FIFE to VERIFONE FAIRVIEW"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Trucks;
