import React from "react";
import { Link } from 'react-router-dom';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';

const CardBox = ({children, name, url, subtitle}) => {
  return (
    <div>
      <Card>
        <span className="m-4">
          {children}
        </span>
        <CardBody>
          <CardTitle tag="h5">{name}</CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            {subtitle}
          </CardSubtitle>
          <CardText>
          </CardText>
            <Link className="btn" to={`${url}`}>
            Go to {name}
            </Link>
        </CardBody>
      </Card>
    </div>
  );
};

export default CardBox;
