import React, {useState, useEffect} from 'react';
import { Redirect, Link } from 'react-router-dom';
import { getShipment, updateShipment, updateXml, updateStrings, writeChangeLog } from '../services/apiCore';
import { isAuthenticated } from '../services/apiAuth';

import Navbar from '../functional/Navbar';
import 'react-datepicker/dist/react-datepicker.css';
import './UpdateShipment.css';

const UpdateShipment = ({match}) => {
  const {user: {Fullname, Clearance, Country}} = isAuthenticated();
  const [values, setValues] = useState({
    Consol_Ref: '',
    Vortex_Reference: '',
    Master_Bill: '',
    House_Bill: '',
    Actual_Pickup: '',
    Actual_Departure: '',
    Actual_Arrival: '',
    Booking_Number: '',
    Origin_Port: '',
    Destination_Port: '',
    Actual_Delivery: '',
    Arrival_Port: '',
    Arrival_Leg: '',
    Arrival_Leg_Type: '',
    Departure_Port: '',
    Departure_Leg: '',
    Departure_Leg_Type: '',
    Transport_Mode: '',
    Packing_Type: '',
    Ap_Xml: '',
    Adep_Xml: '',
    Aa_Xml: '',
    Adel_Xml: '',
    redirectToProfile: '',
    error: '',
    formData: ''
  })

  const {
    Consol_Ref,
    Vortex_Reference,
    Master_Bill,
    House_Bill,
    Actual_Pickup,
    Actual_Departure,
    Actual_Arrival,
    Actual_Delivery,
    Arrival_Port,
    Arrival_Leg,
    Arrival_Leg_Type,
    Booking_Number,
    Origin_Port,
    Destination_Port,
    Arrival_Loading_Port,
    Departure_Port,
    Departure_Leg,
    Departure_Leg_Type,
    Departure_Discharge_Port,
    Transport_Mode,
    Packing_Type,
    Ap_Xml,
    Adep_Xml,
    Aa_Xml,
    Adel_Xml,
    redirectToProfile,
    error,
    formData
  } = values;
  let touch;

  const init = (shipmentId) => {
    createStringsOnInit(shipmentId);
    getShipment(shipmentId).then(data => {
      if (data.error) {
        setValues({ ...values});
      } else {
        setValues({ 
          ...values,
          Consol_Ref: data.Consol_Ref,
          Vortex_Reference: data.Vortex_Reference,
          Master_Bill: data.Master_Bill,
          House_Bill: data.House_Bill,
          Actual_Pickup: data.Actual_Pickup,
          Actual_Departure: data.Actual_Departure,
          Actual_Arrival: data.Actual_Arrival,
          Actual_Delivery: data.Actual_Delivery,
          Arrival_Port: data.Arrival_Port,
          Arrival_Leg: data.Arrival_Leg,
          Arrival_Leg_Type: data.Arrival_Leg_Type,
          Booking_Number: data.Booking_Number,
          Origin_Port: data.Origin_Port,
          Destination_Port: data.Destination_Port,
          Arrival_Loading_Port: data.Arrival_Loading_Port,
          Departure_Port: data.Departure_Port,
          Departure_Leg: data.Departure_Leg,
          Departure_Leg_Type: data.Departure_Leg_Type,
          Departure_Discharge_Port: data.Departure_Discharge_Port,
          Transport_Mode: data.Transport_Mode, 
          Packing_Type: data.Packing_Type,
          Ap_Xml: data.Ap_Xml,
          Adep_Xml: data.Adep_Xml,
          Aa_Xml: data.Aa_Xml,
          Adel_Xml: data.Adel_Xml,
          formData: new FormData()
        })
      }
    })
  }

  useEffect(() => {
    setValues({...values, formData: new FormData()})
    init(match.params.shipmentId)
  }, [])

  /**
   * This method will set the state of the input values once the input was fully submitted/changed
   *  So this function will set the flag to true ("1" bit) if the value was changed, otherwise, the flags will always
   * be set to fasle ("0") that way we prevent unnecessary updates & logic redundancy.
   */
  const handleChange = name => event => {
    const value = event.target.value;
    formData.set(name, value)
   
    setValues({...values, [name]:value}) 
  }

  const createXMLStrings = (event) => {
    let event_type, type_additional,string, event_time, leg_mode, loading_and_discharge, actual_node, 
      legType_node, value, key = Vortex_Reference;

    switch (event) {
      case 'delivery':
        event_type = 'DCF'
        type_additional = "ForwardingShipment.DocsAndCartage.JP_DeliveryCartageCompleted";
        event_time = Actual_Delivery;
        value = Actual_Delivery;
        break;
      case 'departure':
        let departure_lt;
        actual_node = `<ActualDeparture>${Actual_Departure}</ActualDeparture>`
        if (Departure_Leg_Type === 'FL1') {
          departure_lt = 'Flight1'
        } else {
          departure_lt = Departure_Leg_Type
        } 

        legType_node = `<LegType>${departure_lt}</LegType>`
        leg_mode = `${Departure_Leg}`
        loading_and_discharge = 
        `
          <PortOfDischarge>
            <Code>${Departure_Discharge_Port}</Code>
          </PortOfDischarge>
          <PortOfLoading>
            <Code>${Departure_Port}</Code>
          </PortOfLoading>
        `
        break;
      case 'arrival':
        let arrival_lt = Arrival_Leg_Type;
        if (Arrival_Leg_Type === 'FL1')
          arrival_lt = 'Flight1';
        if (Arrival_Leg_Type === 'FL2')
          arrival_lt = 'Flight2';
        if (Arrival_Leg_Type === 'FL3')
          arrival_lt = 'Flight3';
        if (Arrival_Leg_Type === 'MAI')
          arrival_lt = 'Main'
        if (Arrival_Leg_Type === 'OTH')
          arrival_lt = 'Other'

        leg_mode = `${Arrival_Leg}`
        actual_node = `<ActualArrival>${Actual_Arrival}</ActualArrival>`
        legType_node = `<LegType>${arrival_lt}</LegType>`
        loading_and_discharge = `
          <PortOfDischarge>
            <Code>${Arrival_Port}</Code>
          </PortOfDischarge>
          <PortOfLoading>
            <Code>${Arrival_Loading_Port}</Code>
          </PortOfLoading>
        `
        break;
      case 'pickup':
        event_type = 'PCF'
        type_additional = "ForwardingShipment.DocsAndCartage.JP_PickupCartageCompleted";
        event_time = Actual_Pickup;
        value = Actual_Pickup;
      default:
        break;
    }

    if (event === 'pickup' || event === 'delivery') {
      string = `
        <UniversalEvent xmlns="http://www.cargowise.com/Schema/Universal/2011/11">
          <Event>
              <DataContext>
                  <DataTargetCollection>
                      <DataTarget>
                          <Type>ForwardingShipment</Type>
                          <Key>${key}</Key>
                      </DataTarget>
                  </DataTargetCollection>
              </DataContext>
              <Company>
                <Code>PDQ</Code>
                  <Country>
                    <Code>US</Code>
                    <Name>United States</Name>
                  </Country>
                <Name>Vortex Worldwide Logistics, Corp.</Name>
              </Company>
              <EventTime>${event_time}</EventTime>
              <EventType>${event_type}</EventType>
              <AdditionalFieldsToUpdateCollection>
                  <AdditionalFieldsToUpdate>
                      <Type>${type_additional}</Type>
                      <Value>${value}</Value>
                  </AdditionalFieldsToUpdate>
              </AdditionalFieldsToUpdateCollection>
          </Event>
        </UniversalEvent>
      `
    }

    if (event === 'departure' || event === 'arrival') {
      // Where this function will be dealing with the ports and legs
      string = 
      `
        <UniversalShipment xmlns="http://www.cargowise.com/Schemas/Universal/2011/11">
          <Shipment>
            <DataContext>
              <DataTargetCollection>
                <DataTarget>
                  <Type>ForwardingConsol</Type>
                  <Key>${Consol_Ref}</Key>
                </DataTarget>
              </DataTargetCollection>
            </DataContext>
            <TransportLegCollection Content="Partial">
              <TransportLeg>
                ${loading_and_discharge}
                <LegOrder>${leg_mode}</LegOrder>
                ${actual_node}
                ${legType_node}
                <TransportMode>${Transport_Mode}</TransportMode>
              </TransportLeg>
            </TransportLegCollection>
          </Shipment>
        </UniversalShipment>
      `
    }

    return string;
  }

  const createStringsOnInit = () => {
    let shipment = {
      Ap_Xml: createXMLStrings('pickup'),
      Adep_Xml: createXMLStrings('departure'),
      Aa_Xml: createXMLStrings('arrival'),
      Adel_Xml: createXMLStrings('delivery')
    }

    updateStrings(match.params.shipmentId, shipment);
  }

  // TODO: Check if the date fromat func is utilized again in another file (so far 2), if so, move it to a 'service' file
  const formatDate = date => {
    return (new Date(date)).toLocaleDateString('en-US',{timeZone: 'UTC'})
  } 

  const clickSubmit = event => {
    event.preventDefault()

    init(match.params.shipmentId);

    setValues({...values, error: '', loading: true})

    console.log(createXMLStrings('delivery'));
    console.log(createXMLStrings('departure'));
    console.log(createXMLStrings('arrival'));
    console.log(createXMLStrings('pickup'));

    console.log("sendXml() exec-ing...");
    
    let shipment = {
      Vortex_Reference: Vortex_Reference,
      Master_Bill: Master_Bill,
      House_Bill: House_Bill,
      Actual_Pickup: Actual_Pickup,
      Actual_Departure: Actual_Departure,
      Actual_Arrival: Actual_Arrival,
      Actual_Delivery: Actual_Delivery,
      Ap_Xml: createXMLStrings('pickup'),
      Adep_Xml: createXMLStrings('departure'),
      Aa_Xml: createXMLStrings('arrival'),
      Adel_Xml: createXMLStrings('delivery')
    }

    let dates = {
      Actual_Pickup: Actual_Pickup,
      Actual_Departure: Actual_Departure,
      Actual_Arrival: Actual_Arrival,
      Actual_Delivery: Actual_Delivery,
    }

    console.log("This is what is going to be POOOOOSTEED: ",shipment);

    updateShipment(match.params.shipmentId, shipment).then(data => {
      try {
        if(data.error) {
          setValues({...values})
        } else {
          setValues({
            ...values,
            Vortex_Reference: "",
            Master_Bill: "",
            House_Bill: "",
            Actual_Pickup: "",
            Actual_Departure: "",
            Actual_Arrival: "",
            Actual_Delivery: "",
            Ap_Xml: "",
            Adep_Xml: "",
            Aa_Xml: "",
            Adel_Xml: "",
            error: false,
            redirectToProfile: true,
          })
        }
      } catch (error) {
        console.log(error);
      }
    })
    shipment = {
      user: Fullname,
      Vortex_Reference: Vortex_Reference,
      Actual_Pickup: Actual_Pickup,
      Actual_Departure: Actual_Departure,
      Actual_Arrival: Actual_Arrival,
      Actual_Delivery: Actual_Delivery,
    }
    writeChangeLog(shipment);
    updateXml(match.params.shipmentId, dates);
  }
  
  // TODO: show success message
  // Testing

  const newPostForm = () => (
    <>
      <Link to="/tracker">
        <i className="arrow fa fa-arrow-circle-left"></i>
      </Link>
      <h3>Vortex Ref: {Vortex_Reference}</h3>

      {Master_Bill && (
        <h6>MBL: {Master_Bill}</h6>
      )}

      <h6>HBL: {House_Bill}</h6>
      
      <h6>Booking No. {Booking_Number}</h6>
      <h6>Origin: {Origin_Port}</h6>
      <h6>Destination: {Destination_Port}</h6>
      <h6>Transport Mode: {Transport_Mode}</h6>
      <h6>Packing Type: {Packing_Type}</h6>
      <br/>
      <form className="mb-3 update-form" onSubmit={clickSubmit}>
        <div className="form-group">
          <label className={`${Actual_Pickup === '1900-01-01T00:00:00.000Z' ? "bold-text": "text-muted"}`}>Actual Pickup</label>
          {
            // Actual_Pickup == '1900-01-01T00:00:00.000Z' &&
          (
          <input
            placeholder="yyyy-MM-dd"
            onChange={handleChange('Actual_Pickup')}
            type="date"
            className="form-control"
            value={Actual_Pickup} />
          )
          }
          { Actual_Pickup !== '1900-01-01T00:00:00.000Z' &&

            <h6 className="current-val">Current Value: {formatDate(Actual_Pickup)}</h6>
          }
        </div>
        <div className="form-group">
          <label className={` ${Actual_Departure === '1900-01-01T00:00:00.000Z' ? "bold-text" : "text-muted"}`}>Actual Departure</label>
          { 
            // Actual_Departure == '1900-01-01T00:00:00.000Z' &&
            (
              <input 
                placeholder="yyyy-MM-dd"
                onChange={handleChange('Actual_Departure')}
                type="date"
                className="form-control"
                value={Actual_Departure}
                />
            )
          }
          { 
            Actual_Departure !== '1900-01-01T00:00:00.000Z' &&
            <h6 className="current-val">Current Value: {formatDate(Actual_Departure)}</h6>
          }
          {
            Master_Bill === '' && <h6 className="current-val">Master Bill is NULL </h6>
          }
        </div>
        <div className="form-group">
          
          <label className={`${Actual_Arrival === '1900-01-01T00:00:00.000Z' ? "bold-text" : "text-muted"}`}>Actual Arrival</label>
            <br/>
          {
            // Actual_Arrival == '1900-01-01T00:00:00.000Z' &&
            (
              <input  
                placeholder="yyyy-MM-dd"
                onChange={handleChange('Actual_Arrival')}
                type="date"
                className="form-control"
                value={Actual_Arrival} />
            )
          }
          {
            Actual_Arrival !== '1900-01-01T00:00:00.000Z' &&
              <h6 className="current-val">Current Value: {formatDate(Actual_Arrival)}</h6>
          }
          {
            Master_Bill === '' && <h6 className="current-val">Master Bill is NULL </h6>
          }
        </div>
        <div className="form-group">
          <label className={`${Actual_Delivery === '1900-01-01T00:00:00.000Z' ? "bold-text" : "text-muted"}`}>Actual Delivery</label>
          {
            // Actual_Delivery == '1900-01-01T00:00:00.000Z' &&
            (
              <input  
                placeholder="yyyy-MM-dd"
                onChange={handleChange('Actual_Delivery')}
                type="date"
                className="form-control"
                value={Actual_Delivery} />
            )
          }
          {
            Actual_Delivery !== '1900-01-01T00:00:00.000Z' &&
            (
              <h6 className="current-val">Current Value: {formatDate(Actual_Delivery)}</h6>
            )
          }
        </div>
        <button className="btn btn-outline-success">Update</button>
      </form>
    </>
  )

  const redirectUser = () => {
    if(redirectToProfile) {
        return <Redirect to="/tracker"/>
    }
  }
  
  return (
    <div className="upd-cn">
      <Navbar/>
      <div className="update-cont">
        <div className="row">
          <div className="col-md-8 offset-md-2">
            {newPostForm()}
            {redirectUser()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateShipment;