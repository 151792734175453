import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Spinner } from "reactstrap";

import Navbar from "../functional/Navbar";
import { getTrucksFairview } from "../services/apiTrucks";
import { formatDate } from "../helpers/formatterFunctions";

const Fife = () => {
  const [trucks, setTrucks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTrucks();
  }, []);

  const fetchTrucks = () => {
    getTrucksFairview().then((data) => {
      if (data) {
        try {
          setTrucks(data);
          console.log(data);
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const showSpinner = () =>
    loading && (
      <div className="container spin-cont mg-5">
        <Spinner className="spinner" /> <h4>Loading ...</h4>
      </div>
    );

  return (
    <>
      <Navbar />
      {showSpinner()}
      {trucks.length > 0 && !loading && (
        <>
          <h1 className="m-5" style={{ textAlign: "center" }}>
            Fairview
          </h1>

          <div className="container mt-5">
            <table className="table">
              <thead>
                <tr className="cont">
                  <th scope="row header">Vortex Ref. No.</th>
                  <th scope="col">Handling Units</th>
                  <th scope="col">House Bill</th>
                  <th scope="col">Container Delivery </th>
                  <th scope="col">Container Number </th>
                  <th scope="col">Estimated Delivery to Verifone</th>
                  <th scope="col">Actual Delivery to Verifone</th>
                  <th></th>
                </tr>
              </thead>

              {trucks &&
                trucks.map((truck, i) => (
                  <tbody key={i}>
                    <tr className="data">
                      <td>{truck.Vortex_Reference}</td>
                      <td>{truck.Handling_Units}</td>
                      <td>{truck.House_Bill}</td>
                      <td>{truck.Container_Delivery}</td>
                      <td>{truck.Container_Number}</td>
                      <td>
                        {formatDate(
                          truck.Estimated_Date_of_Delivery_to_VERIFONE
                        )}
                      </td>
                      <td>
                        {formatDate(truck.Actual_Date_of_Delivery_to_VERIFONE)}
                      </td>
                      <td>
                        <Link to={`editFairview/${truck.Vortex_Reference}`}>
                          <Button className="btn-edit">Edit</Button>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
        </>
      )}
      {!loading && !trucks.length &&
        <h1 className="mt-5" style={{ textAlign: "center" }}>
          There are no active shipments to CTDI - Fairview
        </h1>
      }
    </>
  );
};

export default Fife;
