import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated} from '../services/apiAuth';

const DashboardUser = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => isAuthenticated() && typeof(isAuthenticated()) 
        !== undefined &&  isAuthenticated().user.Clearance <= 2 ? (
        <Component {...props} />
    ) : (
        <Redirect to={{pathname: "/tracker", state: {from: props.location}}} />
    )} />
)

export default DashboardUser;
