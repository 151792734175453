import React, { useState, useEffect } from "react";
import { Link} from 'react-router-dom';
import { Button, Input, Spinner } from "reactstrap";
import { getShipments, searchShipment, getCredentials } from "../services/apiCore";
import { isAuthenticated } from "../services/apiAuth";
import {formatDate} from '../helpers/formatterFunctions'

import Navbar from '../functional/Navbar';
import "./Tracker.css";

const Tracker = () => {
  let sort = true;
  const [shipments, setShipments] = useState([]);
  const [search, setSearch] = useState({
    Vortex_Reference: "",
    results: [],
  });
  const [values, setValues] = useState({loading: true});
  const {loading} = values;
  const {user: {Fullname, Clearance, Country, Dashboard_Id}} = isAuthenticated();
  
  const [error, setError] = useState(false);
  const {Vortex_Reference, results} = search;
  const handleChange = name => event => {
    setSearch({...shipments, [name]: event.target.value})
  }
  
  // TODO: Check why you are only assigning data.Actual_Pickup here... Looks like it was a proof of concept
  const loadShipments = () => {
    getShipments(Country, Clearance).then(data => {
      if (data) {
        try {
          setShipments(data);
          data.Actual_Pickup = (new Date(data.Actual_Pickup)).toLocaleDateString();
          setValues({...values, loading:false});
        } catch (error) {
          console.log(data.error);
          setError(data.error);
        }
      }
    });
  };

  const searchSubmit = (e) => {
    e.preventDefault();
    fetchData();
  }

  const sortShipments =  (a,b) => {
    sort = !sort;
  }

  const fetchData = () => {
    if (Vortex_Reference) {
        searchShipment({Vortex_Reference}, Country, Clearance)
        .then(response => {
            if(response.error) {
                console.log("Error React: ", response.error);
            }  else {
                setSearch({...search, results: response});
            }
        });
    }
  }

  // TODO: Check if the dateformat (already in two files) is utilized again, if so, move it to a 'service' file
  // const formatDate = date => {
  //   return (new Date(date)).toLocaleDateString('en-US',{timeZone: 'UTC'})
  // } 

  const showSpinner = () => 
      loading && (
      <div className="container spin-cont mg-5">
        <Spinner className="spinner" />{' '}
        <h4>Loading ...</h4>
      </div>
  )  

  const TrackerContainer = () => (
    <>
      <div className="container-fluid">
        <table className="table">
          <thead>
            <tr className="cont">
              <th scope="row header">Vortex Ref. No.</th>
              <th scope="col">Master Bill of Lading</th>
              <th scope="col">House Bill </th>
              <th scope="col">Booking No. </th>
              <th scope="col">Actual Pick Up</th>
              <th scope="col">Actual Departure</th>
              <th scope="col">Actual Arrival</th>
              <th scope="col">Actual Delivery</th>
              <th></th>
            </tr>
          </thead>

          {/* This is the display when all shipments are fetched */}
          { search.Vortex_Reference === "" && shipments.map((shipment, i) => (
            <tbody key={i}>
              <tr className="data">
                <td>{shipment.Vortex_Reference}</td>
                <td>{shipment.Master_Bill}</td>
                <td>{shipment.House_Bill}</td>
                <td>{shipment.Booking_Number}</td>
                <td>{shipment.Actual_Pickup === "1900-01-01T00:00:00.000Z" ? "" : formatDate(shipment.Actual_Pickup)}</td>
                <td>{shipment.Actual_Departure === "1900-01-01T00:00:00.000Z" ? "" : formatDate(shipment.Actual_Departure)}</td>
                <td>{shipment.Actual_Arrival === "1900-01-01T00:00:00.000Z" ? "" : formatDate(shipment.Actual_Arrival)}</td>
                <td>{shipment.Actual_Delivery === "1900-01-01T00:00:00.000Z" ? "" : formatDate(shipment.Actual_Delivery)}</td>

                <td>
                  {/* <ModalComponent buttonLabel="edit" shipment={shipment}/> */}
                  <Link to={`shipments/edit/${shipment.Vortex_Reference}`}>
                    <Button className="btn-edit">Edit</Button>
                  </Link>
                </td>
              </tr>
            </tbody>
          ))}

          {/* This is the display when the user makes a query for specific shipments, this is the code for those results */}
          { search.Vortex_Reference !== "" && results && results.map((shipment, i) => (
            <tbody key={i}>
              <tr className="data">
                <td>{shipment.Vortex_Reference}</td>
                <td>{shipment.Master_Bill}</td>
                <td>{shipment.House_Bill}</td>
                <td>{shipment.Booking_Number}</td>
                <td>{shipment.Actual_Pickup === "1900-01-01T00:00:00.000Z" ? "" : formatDate(shipment.Actual_Pickup)}</td>
                <td>{shipment.Actual_Departure === "1900-01-01T00:00:00.000Z" ? "" : formatDate(shipment.Actual_Departure)}</td>
                <td>{shipment.Actual_Arrival === "1900-01-01T00:00:00.000Z" ? "" : formatDate(shipment.Actual_Arrival)}</td>
                <td>{shipment.Actual_Delivery === "1900-01-01T00:00:00.000Z" ? "" : formatDate(shipment.Actual_Delivery)}</td>
                <td>
                  <Link to={`shipments/edit/${shipment.Vortex_Reference}`}>
                    <Button>Edit</Button>
                  </Link>
                </td>
              </tr>
            </tbody>
          ))}

        </table>
      </div>
    </>
  );

  useEffect(() => {
    setValues({ ...values, loading: true});
    console.log("Search: ",search);
    console.log("Search length", search.length);
    loadShipments();
  }, []);

  // TODO: think that maybe it could be good to create to functions, one that renders the initial fetch
  // - the second function fetches the results from the search query
  return (
    <div>
      <Navbar />
      <div className="container">
        <h2 className="name">Welcome {Fullname}</h2>
      </div>

      {showSpinner()}
      <div className="container">
        <form onSubmit={searchSubmit}>
          <div className="row">
            <div className="col-10">
              <Input className="form-control" placeholder="Search here" onChangeCapture={handleChange('Vortex_Reference')} />
            </div>
            <div className="col-1">
              <Button className="clearAll"><i className="fa fa-search"></i></Button>
            </div>
          </div>
        </form>
      </div>
      {TrackerContainer()}
    </div>
  );
};

export default Tracker;
